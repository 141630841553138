export default (value: string, opts: any, t: Function) => {
  const { name, id } = opts?.required?.if ?? {};
  value = value?.toString().trim();

  if (name || id) {
    const selector = name ? `[name=${name}]` : `#${id}`;
    const el: HTMLInputElement | null = document.querySelector(selector);
    if (el) {
      const val = el.getAttribute("data-value") ?? el.value;
      return val?.length || value?.length || t("This field is required");
    }
  }

  return value?.length || t("This field is required");
};
