import { FunctionComponent, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import Avatar from "../../Avatar";

interface VideoProps {
  stream?: MediaStream;
  muted?: boolean;
  firstName?: string;
  lastName?: string;
  image?: any;
  videoEnabled?: boolean;
  audioEnabled?: boolean;
}

const Video: FunctionComponent<VideoProps> = ({
  stream,
  muted = false,
  videoEnabled = false,
  audioEnabled = false,
  firstName,
  lastName,
  image,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef.current]);

  return (
    <>
      {!videoEnabled && (
        <div className="flex flex-1 justify-center items-center relative z-20">
          <Avatar
            firstName={firstName}
            lastName={lastName}
            image={image}
            className="w-full h-full max-w-32 max-h-32 bg-white text-black text-3xl object-cover rounded-full"
            dataTestId={`${firstName}-${lastName}-avatar`}
          />
        </div>
      )}
      <video
        ref={videoRef}
        className="w-full h-full left-1/2 transform -translate-x-1/2 -scale-x-100 object-cover absolute z-10"
        autoPlay
        playsInline
        muted={muted}
        data-testid={`${firstName}-${lastName}-video`}
      />
      <div className="absolute z-30 w-full flex items-center bottom-0 left-0 mb-1 px-2">
        <div className="max-w-[250px] h-6 flex items-center px-2 text-sm rounded bg-black/50 truncate">
          <span>
            {firstName} {lastName}
          </span>
        </div>
        <div className="h-6 px-2 rounded bg-black/50 flex items-center gap-x-4 ml-auto">
          <div>
            <Icon
              icon={
                audioEnabled
                  ? "clarity:microphone-solid"
                  : "clarity:microphone-mute-solid"
              }
              className="las text-base text-white"
              data-testid={
                audioEnabled ? "microphone-icon" : "microphone-off-icon"
              }
            />
          </div>
          <div>
            <Icon
              icon={
                videoEnabled
                  ? "fluent:video-32-filled"
                  : "fluent:video-off-32-filled"
              }
              className="las text-base text-white"
              data-testid={videoEnabled ? "video-icon" : "video-off-icon"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
