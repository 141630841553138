import { FunctionComponent } from "react";
import { classNames } from "utils";
import VideoDisplay from "../Video/VideoDisplay";
import UserVideo from "../Video/UserVideo";
import useMeetingStore from "../store/MeetingStore";

const VideoGrid: FunctionComponent = () => {
  const gridUsers = useMeetingStore((state) => state.gridUsers);

  return (
    <>
      {!gridUsers?.length && (
        <UserVideo className="w-full h-full max-w-[1024px] max-h-[768px]" />
      )}
      {!!gridUsers?.length && (
        <div className="w-full h-full max-w-[1300px] max-h-[900px] flex items-center justify-center">
          <div
            className={classNames(
              gridUsers.length === 1 && "grid-1",
              gridUsers.length === 2 && "grid-2",
              gridUsers.length === 3 && "grid-3",
              "video-grid w-full h-full grid grid-cols-2 grid-rows-2 gap-4"
            )}
          >
            {gridUsers.map((user: any, index: number) => (
              <VideoDisplay
                key={user.id}
                participant={user}
                className={classNames(
                  index >= 1 && "hidden lg:flex",
                  "grid-item"
                )}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default VideoGrid;
