import { create } from "zustand";
import { devtools } from "zustand/middleware";

type PermissionState = "granted" | "denied" | "prompt";

interface Permissions {
  camera: PermissionState;
  microphone: PermissionState;
}

interface MediaDevices {
  cameras: MediaDeviceInfo[];
  microphones: MediaDeviceInfo[];
  speakers: MediaDeviceInfo[];
}

interface UserMediaStoreProps {
  permissions: Permissions;
  devices: MediaDevices;
  selectedCamera?: string;
  selectedMicrophone?: string;
  selectedSpeaker?: string;
  setPermissions: (permissions: Permissions) => void;
  setDevices: (devices: MediaDevices) => void;
  setSelectedCamera: (cameraId: string) => void;
  setSelectedMicrophone: (microphoneId: string) => void;
  setSelectedSpeaker: (speakerId: string) => void;
}

const UserMediaStore = create(
  devtools<UserMediaStoreProps>((set) => ({
    stream: undefined,
    isTalking: false,
    permissions: {
      camera: "prompt",
      microphone: "prompt",
    },
    devices: {
      cameras: [],
      microphones: [],
      speakers: [],
    },
    selectedCamera: undefined,
    selectedMicrophone: undefined,
    selectedSpeaker: undefined,
    setPermissions: (newPermissions) =>
      set((state: any) => {
        if (
          state.permissions.camera !== newPermissions.camera ||
          state.permissions.microphone !== newPermissions.microphone
        ) {
          return { permissions: newPermissions };
        }
        return state.permissions;
      }),
    setDevices: (devices) => {
      set({ devices });
    },
    setSelectedCamera: (selectedCamera) => {
      set({ selectedCamera });
    },
    setSelectedMicrophone: (selectedMicrophone) => {
      set({ selectedMicrophone });
    },
    setSelectedSpeaker: (selectedSpeaker) => {
      set({ selectedSpeaker });
    },
  }))
);

export default UserMediaStore;
