import { toast } from "react-toastify";

interface ErrorProps {
  message: string;
}

const Error = ({ message }: ErrorProps) => {
  return toast.error(message, {
    style: {
      backgroundColor: "#fee2e2",
      color: "#9b2c2c",
      fontWeight: 300,
      fontSize: "0.9rem",
    },
  });
};

export default Error;
