/* eslint-disable */
import axios from "axios";
import Progress from "nprogress";
import Notify from "ui/Notify";
import { logout } from "utils";
import {
  isClient,
  HTTP_UNAUTHORIZED,
  AXIOS_CANCELED_CODE,
} from "utils/constants";

const startProgressBar = () => {
  if (isClient) {
    Progress.start();
  }
};

const stopProgressBar = () => {
  if (isClient) {
    Progress.done();
  }
};

axios.interceptors.request.use(
  (request) => {
    // Starts progress bar
    startProgressBar();

    return request;
  },
  (err) => {
    stopProgressBar();
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (response) => {
    const res = response?.data?.data;

    // This automatically captures status messages sent
    // from backend to frontend and display them to the user.
    if (res) {
      const key = Object.keys(res)?.[0];
      const data = res?.[key];

      const { status, message } = data ?? {};

      if (isClient && status && message) {
        Notify({ message, status });
      }
    }

    // Stops progress bar
    stopProgressBar();

    return response;
  },
  (err) => {
    const { response, code } = err;

    stopProgressBar();

    if (code === AXIOS_CANCELED_CODE) {
      return Promise.resolve(null);
    }

    if (response?.status === HTTP_UNAUTHORIZED) {
      logout();
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(err);
  }
);
