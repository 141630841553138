import { FunctionComponent } from "react";
import Video from "../Video/Video";
import VideoDisplay from "../Video/VideoDisplay";
import useMeetingStore from "../store/MeetingStore";

const ScreenShareConferenceGrid: FunctionComponent = () => {
  const gridUsers = useMeetingStore((state) => state.gridUsers);
  const me = useMeetingStore((state) => state.me);
  const screenSharingUser = useMeetingStore((state) => state.screenSharingUser);

  return (
    <div className="relative w-full h-full flex flex-col">
      {!!gridUsers.length && (
        <div className="relative w-full h-40 flex justify-center px-6 horizontal-video-grid">
          {gridUsers.map((user: any) => (
            <VideoDisplay key={user.id} participant={user} />
          ))}
        </div>
      )}
      <div className="flex-1 flex justify-center items-center">
        <div className="w-full max-w-7xl">
          <div className="aspect-video relative z-10 border-4 border-black shadow-lg rounded-lg bg-black overflow-hidden screen-share-display">
            <Video
              firstName={screenSharingUser?.firstName}
              lastName={screenSharingUser?.lastName}
              image={screenSharingUser?.image}
              stream={screenSharingUser?.stream}
              videoEnabled={screenSharingUser?.videoEnabled}
              audioEnabled={screenSharingUser?.audioEnabled}
              muted={me?.id === screenSharingUser?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenShareConferenceGrid;
