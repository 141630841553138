import React, { FunctionComponent, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { classNames } from "utils";
import "./Modal.scss";

const icon = {
  alert: "ph:warning-bold",
  confirm: "octicon:question-16",
  info: "mi:circle-information",
  success: "icon-park-outline:good-two",
};

interface ModalProps {
  state: [boolean, (value: boolean) => void];
  title?: string;
  type: "alert" | "success" | "info" | "confirm";
  children: any;
}

interface ModalBodyProps {
  children: any;
}

interface ModalFooterProps {
  children: any;
}

// Helper function to traverse children deeply and extract body and footer content
const extractContent = (children: any) => {
  const bodyContent: any = [];
  let footerContent: any = [];

  const traverseChildren = (nodes: any) => {
    React.Children.forEach(nodes, (child: any) => {
      if (React.isValidElement(child)) {
        // @ts-ignore
        if (child.type.componentType === "ModalFooter") {
          footerContent.push(child);
          // @ts-ignore
        } else if (child.type.componentType === "ModalBody") {
          bodyContent.push(child);
          // @ts-ignore
        } else if (child?.props?.children) {
          // @ts-ignore
          traverseChildren(child.props.children);
        }
      }
    });
  };

  traverseChildren(children);

  return { bodyContent, footerContent };
};

const Modal: FunctionComponent<ModalProps> & {
  Body: FunctionComponent<ModalBodyProps>;
  Footer: FunctionComponent<ModalFooterProps>;
} = ({ state, title, type, children }) => {
  const [open, setOpen] = state;
  const cancelButtonRef = useRef(null);
  const { bodyContent, footerContent } = extractContent(children);

  return (
    <Transition.Root appear show={open} as="div">
      <Dialog
        as="div"
        className="fixed inset-0 z-[999] overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="block min-h-screen w-full px-4 text-center sm:p-0">
          <div className="fixed inset-0 backdrop-blur-sm" aria-hidden="true" />
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              data-testid="modal-overlay"
              className="fixed inset-0 bg-black/25"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as="span"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="mb-20 inline-block w-full transform rounded-lg bg-stone-100 text-left align-bottom shadow-xl transition-all sm:mb-0 sm:max-w-lg sm:align-middle">
              <div className="absolute right-0 top-0 mr-1 mt-1 flex items-center gap-x-1">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="flex h-6 w-6 items-center justify-center rounded-md border border-white/50 text-white/50 hover:border-white hover:text-white"
                  data-testid="Close"
                >
                  <Icon icon="mingcute:close-fill" className="text-md" />
                </button>
              </div>
              {title && (
                <div
                  className={classNames(
                    type === "confirm" && "bg-blue-800",
                    type === "info" && "bg-blue-800",
                    type === "alert" && "bg-red-800",
                    type === "success" && "bg-green-800",
                    "flex items-center rounded-t-lg px-4 py-3",
                  )}
                >
                  <div className="mr-3 flex items-center justify-center">
                    <Icon
                      icon={icon[type]}
                      className="text-white"
                      width="24"
                      height="24"
                    />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-white">{title}</p>
                  </div>
                </div>
              )}

              <div className="modal-content p-6 text-sm font-medium text-slate-600">
                {bodyContent}
              </div>

              {!!footerContent?.length && (
                <div className="border-t border-stone-200 p-2">
                  {footerContent}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Modal.Body = ({ children }: ModalBodyProps) => <>{children}</>;
// @ts-ignore
Modal.Body.componentType = "ModalBody";

Modal.Footer = ({ children }: ModalFooterProps) => <>{children}</>;
// @ts-ignore
Modal.Footer.componentType = "ModalFooter";

export default Modal;
