import { FunctionComponent, useEffect } from "react";
import useSocketStore from "./store/SocketStore";
import useMeetingStore from "./store/MeetingStore";
import useUserMedia from "./hooks/useUserMedia";
import JoinMeeting from "./JoinMeeting";
import Conference from "./Conference";
import "./styles.scss";

const Meeting: FunctionComponent<any> = ({ user }) => {
  const { checkPermissions, getEnumeratedDevices } = useUserMedia();
  const socket = useSocketStore((state) => state.socket);
  const connect = useSocketStore((state) => state.connect);
  const disconnect = useSocketStore((state) => state.disconnect);
  const roomId = useMeetingStore((state) => state.roomId);
  const setMe = useMeetingStore((state) => state.setMe);

  useEffect(() => {
    if (user && socket?.connected) {
      setMe({
        id: socket.id,
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.image,
      });
    }
  }, [user, setMe, socket]);

  useEffect(() => {
    checkPermissions();
    getEnumeratedDevices();
  }, []);

  useEffect(() => {
    connect();

    return () => {
      disconnect();
    };
  }, []);

  return (
    <>
      {!roomId && <JoinMeeting />}
      {roomId && <Conference />}
    </>
  );
};

export default Meeting;
