import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { setValidation, ValidationRulesInterface } from "../validation";

interface ValidationProps {
  name: string;
  state: [any, (value: any) => void];
  validate?: ValidationRulesInterface;
}

interface ButtonHookProps {
  validate?: boolean;
  onClick: Function;
}

const isPromise = (value: any): boolean => {
  return (
    value instanceof Promise ||
    (typeof value === "object" &&
      value !== null &&
      typeof value.then === "function" &&
      typeof value.catch === "function")
  );
};

const useValidation = ({ state, name, validate }: ValidationProps) => {
  const { t } = useTranslation("validation");
  const [value, setValue] = state;
  const [error, setError] = useState<string | undefined>(undefined);
  const {
    setValue: setInput,
    trigger,
    register,
    formState: { errors },
  } = useFormContext();

  const validateValue = (val: any) => {
    setValue(val);
    setInput(name, val);
    trigger(name);
  };

  const rules = register(name, {
    ...setValidation(validate, t),
  });

  const { onChange: onInputChanged } = rules;

  const onChange = (event: any) => {
    if (event?.target?.files?.length) {
      setValue(event?.target?.files?.[0]);
    } else {
      setValue(event.target.value);
    }
    onInputChanged(event);
  };

  useEffect(() => {
    setInput(name, value);
  }, [value]);

  useEffect(() => {
    setError((errors?.[name]?.message as string) || undefined);
  }, [errors[name]]);

  return { value, setValue, onChange, rules, error, errors, validateValue };
};

const useButtonHook = ({ onClick, validate }: ButtonHookProps) => {
  const [loading, setLoading] = useState(false);
  const mountedRef = useRef(true);
  const { trigger } = useFormContext();

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleClick = async () => {
    if (validate) {
      const valid = await trigger();

      if (loading || !valid) {
        return;
      }
    }

    const func = onClick();

    if (isPromise(func)) {
      setLoading(true);
      const timer = 1000;

      // Show the animation for at least one sec
      setTimeout(() => {
        func
          .then(() => {
            // If component has already been dismounted,
            // don't execute setLoading(false) otherwise
            // it returns an error since the component no longer exists
            if (!mountedRef.current) {
              return;
            }

            setLoading(false);
          })
          .catch(() => {
            if (!mountedRef.current) {
              return;
            }

            setLoading(false);
          });
      }, timer);
    }
  };

  return { handleClick, loading };
};

export { useValidation, useButtonHook };
