import { FunctionComponent, useEffect, useState } from "react";
export interface PathSchema {
  path: string;
}

export interface ImageSchema {
  xxlarge: PathSchema;
  xlarge: PathSchema;
  large: PathSchema;
  medium: PathSchema;
  small: PathSchema;
}

interface AvatarProps {
  image?: ImageSchema;
  className?: string;
  firstName?: string;
  lastName?: string;
  alt?: string;
  dataTestId?: string;
}

const Avatar: FunctionComponent<AvatarProps> = ({
  image,
  firstName,
  lastName,
  className = "",
  alt = "",
  dataTestId,
}) => {
  const [initials, setInitials] = useState<string | undefined>();

  useEffect(() => {
    if (firstName && lastName) {
      setInitials(firstName[0] + lastName[0]);
    }
  }, [firstName, lastName]);

  return (
    <>
      {image?.small?.path && (
        <img
          src={image.small.path}
          className={`object-center object-cover ${className}`}
          data-testid={dataTestId}
          alt={alt}
        />
      )}
      {!image?.small?.path && initials && (
        <div className={`flex justify-center items-center ${className}`}>
          <span className="tracking-tight font-semibold">{initials}</span>
        </div>
      )}
      {!image?.small?.path && !initials && (
        <div className={`overflow-hidden ${className}`}>
          <svg
            className="h-full w-full"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </div>
      )}
    </>
  );
};

export default Avatar;
