// QVGA 320x240
// SD 640x480
// HD 1280x720
// FHD 1920x1080
// UHD 3840x2160
const VideoConstraints = {
  width: { ideal: 640, max: 640 },
  height: { ideal: 480, max: 480 },
  frameRate: { min: 10, ideal: 15, max: 30 },
};
const AudioConstraints = {
  sampleRate: { min: 8000, ideal: 16000, max: 48000 },
  channelCount: { ideal: 1, max: 1 },
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true,
};

export const MediaConstraints = {
  audio: AudioConstraints,
  video: VideoConstraints,
};

export const IceConfiguration = {
  iceServers: [
    {
      urls: [
        "stun:stun.l.google.com:19302",
        "stun:stun1.l.google.com:19302",
        "stun:stun2.l.google.com:19302",
        "stun:stun3.l.google.com:19302",
        "stun:stun4.l.google.com:19302",
        "stun:stun.ekiga.net",
        "stun:stun.ideasip.com",
        "stun:stun.stunprotocol.org:3478",
      ],
    },
    // TURN servers should be added here
    // {
    //   urls: 'turn:your-turn-server.com:3478',
    //   username: 'username',
    //   credential: 'password'
    // }
  ],
  iceTransportPolicy: "all" as RTCIceTransportPolicy,
  rtcpMuxPolicy: "require" as RTCRtcpMuxPolicy,
  bundlePolicy: "max-bundle" as RTCBundlePolicy,
  iceCandidatePoolSize: 10,
};
