import { FunctionComponent, useState } from "react";
import { Icon } from "@iconify/react";
import { classNames } from "utils";
import ParticipantList from "./ParticipantList";
import UserVideo from "./Video/UserVideo";
import useStreamStore from "./store/StreamStore";
import useMeetingStore from "./store/MeetingStore";
const XL_SCREEN = 1280;

const Sidebar: FunctionComponent = () => {
  const [show, setShow] = useState(window.innerWidth > XL_SCREEN);
  const isUserSharingScreen = useStreamStore((state) => state.hasScreenTrack);
  const hasParticipants = useMeetingStore(
    (state) => !!state.participants?.length
  );

  return (
    <div className="absolute lg:relative z-20 top-0 right-0 h-full">
      <button
        type="button"
        className={classNames(
          show ? "-ml-3" : "-ml-7",
          "absolute top-0 left-0 h-6 w-6 mt-4 bg-gray-800 flex items-center justify-center rounded-full"
        )}
        onClick={() => setShow(!show)}
      >
        {!show && (
          <Icon icon="ci:arrow-left-sm" className="h-5 w-5 text-white" />
        )}
        {show && (
          <Icon icon="ci:arrow-right-sm" className="h-5 w-5 text-white" />
        )}
      </button>
      <div
        className={classNames(
          show ? "w-80" : "w-0",
          "h-full flex flex-col bg-gray-800 border-l border-black transition-all duration-300 ease-in-out"
        )}
      >
        {show && (
          <>
            <ParticipantList />
            {hasParticipants && !isUserSharingScreen && (
              <div className="mt-auto p-2">
                <UserVideo className="w-full h-[220px]" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
