export const HTTP_PAGE_OK = 200;
export const HTTP_NOT_FOUND = 404;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_INTERNAL_SERVER_ERROR = 500;

export const AXIOS_CANCELED_CODE = "ERR_CANCELED";

export const isServer = import.meta.env.SSR;
export const isClient = !isServer;

export const isProd = import.meta.env.MODE === "production";
export const isTest = import.meta.env.MODE === "test";
export const isDev = import.meta.env.MODE === "development";

export const apiURL = import.meta.env.VITE_API_URL;
