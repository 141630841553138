import { FunctionComponent } from "react";
import { Icon } from "@iconify/react";
import Avatar from "../../Avatar";

const ParticipantItem: FunctionComponent<any> = ({ participant }) => {
  return (
    <div className="w-full flex items-center justify-between py-2 px-3 border-b border-gray-800 bg-gray-700">
      <div className="flex items-center">
        <Avatar
          firstName={participant?.firstName}
          lastName={participant?.lastName}
          image={participant?.image}
          className="w-8 h-8 bg-gray-200 text-gray-700 rounded-full mr-3"
          dataTestId={`avatar-${participant?.id}`}
        />
        <div>
          <div className="w-40 text-sm font-medium truncate">
            {participant?.firstName} {participant?.lastName}
          </div>
          <div className="flex items-center text-xs text-gray-500">
            <span className="text-green-500">Online</span>
            {!!participant?.isTalking && (
              <Icon
                icon="ant-design:sound-filled"
                className="text-white text-base ml-1"
                data-testid="isTalkingIcon"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-x-1">
        <div className="p-2 bg-white/20 rounded-full">
          <Icon
            icon={
              participant?.audioEnabled
                ? "clarity:microphone-solid"
                : "clarity:microphone-mute-solid"
            }
            className="las text-white"
            data-testid={participant?.audioEnabled ? "micOnIcon" : "micOffIcon"}
          />
        </div>
        <div className="p-2 bg-white/20 rounded-full">
          <Icon
            icon={
              participant?.videoEnabled
                ? "fluent:video-32-filled"
                : "fluent:video-off-32-filled"
            }
            className="las text-white"
            data-testid={
              participant?.videoEnabled ? "videoOnIcon" : "videoOffIcon"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ParticipantItem;
