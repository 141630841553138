import { useEffect, useState, useRef } from "react";

interface UseWebWorkerResult {
  postMessage: (message: any) => void;
  result: any;
  error: any;
  loading: boolean;
}

const useWebWorker = (workerFunction: () => void): UseWebWorkerResult => {
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const workerRef = useRef<Worker | null>(null);

  const postMessage = (message: any) => {
    if (!workerRef.current) return;

    setLoading(true);
    workerRef.current.postMessage(message);
  };

  useEffect(() => {
    if (!workerFunction) return;

    const blob = new Blob([`(${workerFunction.toString()})()`], {
      type: "application/javascript",
    });

    const worker = new Worker(URL.createObjectURL(blob));

    workerRef.current = worker;

    return () => {
      workerRef.current?.terminate();
      workerRef.current = null;
    };
  }, [workerFunction]);

  useEffect(() => {
    if (!workerRef.current) return;

    const onMessage = (event: MessageEvent) => {
      setResult(event.data);
      setLoading(false);
    };

    const onError = (event: ErrorEvent) => {
      setError(event.error);
      setLoading(false);
    };

    workerRef.current.addEventListener("message", onMessage);
    workerRef.current.addEventListener("error", onError);

    return () => {
      workerRef.current?.removeEventListener("message", onMessage);
      workerRef.current?.removeEventListener("error", onError);
    };
  }, [workerRef.current]);

  return { postMessage, result, error, loading };
};

export default useWebWorker;
