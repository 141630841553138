import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useMeetingStore from "./store/MeetingStore";
import useStreamStore from "./store/StreamStore";
import useSocketStore from "./store/SocketStore";
import Logo from "../Logo";
import LoadingSpinner from "../LoadingSpinner";
import MediaSetup from "./MediaControls/MediaSetup";

const JoinMeeting: FunctionComponent = () => {
  const params = useParams();
  const [roomId, setRoomId] = useState<string | undefined>();
  const [fullName, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const audioEnabled = useStreamStore((state) => state.audioEnabled);
  const videoEnabled = useStreamStore((state) => state.videoEnabled);
  const socket = useSocketStore((state) => state.socket);
  const me = useMeetingStore((state) => state.me);
  const setMe = useMeetingStore((state) => state.setMe);
  const setParticipants = useMeetingStore((state) => state.setParticipants);
  const joinRoom = useMeetingStore((state) => state.joinRoom);

  const join = () => {
    setLoading(true);

    if (me && roomId) {
      socket?.emit(
        "meeting.user.join",
        {
          roomId,
          user: {
            ...me,
            audioEnabled,
            videoEnabled,
          },
        },
        (participants: any) => {
          if (!participants) {
            // Something went wrong, do not enter room
            return;
          }

          setParticipants(participants);
          joinRoom(roomId);
        }
      );
      return;
    }

    if (fullName.trim() === "") {
      setLoading(false);
      return;
    }

    const names = fullName.trim().split(" ");
    const firstName =
      names.length > 1 ? names.slice(0, names.length - 1).join(" ") : names[0];
    const lastName = names.length > 1 ? names[names.length - 1] : " ";

    setMe({
      firstName,
      lastName,
      image: null,
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    setRoomId(params.id);
  }, [params]);

  useEffect(() => {
    if (me?.firstName && me?.lastName) {
      setFullName(`${me.firstName} ${me.lastName}`);
    }
  }, [me]);

  return (
    <section className="w-full h-full min-h-screen relative lg:fixed lg:flex items-center bg-blue-800">
      <Logo white className="absolute top-0 left-0 mt-6 ml-6" />
      <div className="px-4 mx-auto pt-32 lg:pt-0 relative sm:px-6 lg:px-8 max-w-7xl overflow-x-hidden md:overflow-x-visible">
        <div className="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 gap-x-16">
          <div>
            <h1 className="text-4xl font-normal text-white sm:text-5xl lg:text-6xl xl:text-7xl">
              Connecting Lawyers with Clients
              <span className="text-sky-500">.</span>
            </h1>
            <p className="mt-4 text-lg font-normal text-slate-300 sm:mt-8">
              Streamline Case Management and Consult with Your Lawyer Anytime,
              Anywhere through Video Conferencing
            </p>

            {roomId && (
              <div className="relative mt-8 rounded-full sm:mt-12">
                <div className="relative">
                  <div className="absolute rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500"></div>
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      disabled={!!me?.firstName && !!me?.lastName}
                      placeholder="Type your full name here"
                      className="block w-full py-4 pr-6 text-white placeholder-gray-500 bg-black border border-transparent rounded-full pl-8 sm:py-5 focus:border-transparent focus:ring-0"
                    />
                  </div>
                </div>
                <div className="sm:absolute flex sm:right-1.5 sm:inset-y-1.5 mt-4 sm:mt-0">
                  {loading && <LoadingSpinner size={13} />}
                  {!loading && (
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-full px-5 py-5 text-sm font-semibold tracking-widest text-black uppercase transition-all duration-200 bg-white rounded-full sm:w-auto sm:py-3 hover:opacity-90"
                      onClick={join}
                    >
                      {me?.firstName && me?.lastName
                        ? "Join Meeting"
                        : "Add Name"}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="relative flex justify-center">
            <div className="absolute inset-0">
              <svg
                className="blur-3xl filter opacity-20"
                width="444"
                height="536"
                viewBox="0 0 444 536"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M225.919 112.719C343.98 64.6648 389.388 -70.487 437.442 47.574C485.496 165.635 253.266 481.381 135.205 529.435C17.1445 577.488 57.9596 339.654 9.9057 221.593C-38.1482 103.532 107.858 160.773 225.919 112.719Z"
                  fill="url(#c)"
                />
                <defs>
                  <linearGradient
                    id="c"
                    x1="82.7339"
                    y1="550.792"
                    x2="-39.945"
                    y2="118.965"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0%" />
                    <stop offset="100%" />
                  </linearGradient>
                </defs>
              </svg>
            </div>

            {!me && (
              <img
                className="relative w-full max-w-md mx-auto"
                src="/images/connecting-lawyers-clients.png"
                alt=""
              />
            )}
            {me && <MediaSetup />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinMeeting;
