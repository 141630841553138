import { create } from "zustand";
import { devtools } from "zustand/middleware";

type BreadcrumbProps = { title: string; to: string }[];

interface BreadcrumbState {
  links: BreadcrumbProps;
  setBreadcrumb: (payload: BreadcrumbProps) => void;
}

const useBreadcrumbStore = create<BreadcrumbState>()(
  devtools((set) => ({
    links: [] as BreadcrumbProps,
    setBreadcrumb: (links) => {
      set({ links });
    },
  }))
);

export default useBreadcrumbStore;
