export const areObjectsEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const areArraysOfObjectsEqual = (
  arr1: Array<any>,
  arr2: Array<any>
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const visited = new Array(arr2.length).fill(false);

  for (let obj1 of arr1) {
    let found = false;
    for (let i = 0; i < arr2.length; i++) {
      if (!visited[i] && areObjectsEqual(obj1, arr2[i])) {
        visited[i] = true;
        found = true;
        break;
      }
    }
    if (!found) {
      return false;
    }
  }

  return true;
};
