import { StylesConfig, CSSObjectWithLabel, OptionProps } from "react-select";

const setBackGroundColor = (selectState: OptionProps) => {
  if (selectState.isSelected) {
    return "#3b82f6";
  }

  if (selectState.isFocused) {
    return "#d6ebff";
  }

  return "transparent";
};

const setTextColor = (selectState: OptionProps) => {
  if (selectState.isSelected) {
    return "white";
  }

  if (selectState.isFocused) {
    return "black";
  }

  return "";
};

const SelectStyle = (hasError: boolean): StylesConfig => {
  return {
    option: (provided: CSSObjectWithLabel, selectState: OptionProps) => ({
      ...provided,
      color: setTextColor(selectState),
      backgroundColor: setBackGroundColor(selectState),
      fontSize: "13px",
      "&:hover": { backgroundColor: "#d6ebff", color: "black" },
    }),

    control: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: "0.375rem",
      padding: "0 0.4rem 0 0.4rem",
      boxShadow: "none",
      backgroundColor: "#f1f5f9",
      fontWeight: 400,
      fontSize: "14px",
      borderColor: hasError ? "#dc2626" : "#e2e8f0",
      "&:hover": { borderColor: "#94a3b8" },
      "&:focus": { boxShadow: "none", border: "none" },
    }),

    input: (base: CSSObjectWithLabel) => ({
      ...base,
      boxShadow: "none",
      fontWeight: 400,
      color: "#475569",
      "&:focus": {
        boxShadow: "none",
        outline: "none", // Add this line to remove the outline
      },
      // Add these lines to remove any remaining outline or ring
      "& input": {
        boxShadow: "none !important",
        outline: "none !important",
      },
      "& input:focus": {
        boxShadow: "none !important",
        outline: "none !important",
      },
    }),

    placeholder: (base: CSSObjectWithLabel) => ({
      ...base,
      fontSize: "14px",
      fontWeight: 200,
      color: "#64748b",
    }),

    singleValue: (base: CSSObjectWithLabel) => ({
      ...base,
      fontWeight: 400,
      color: "#475569",
    }),

    multiValueLabel: (base: CSSObjectWithLabel) => ({
      ...base,
      fontWeight: 400,
      backgroundColor: "#64748b",
      color: "#ffffff",
      borderRadius: "4px 0 0 4px",
    }),

    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      backgroundColor: "#64748b",
      color: "#ffffff",
      borderRadius: "0 4px 4px 0",
    }),
  };
};

export default SelectStyle;
