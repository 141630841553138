import { FunctionComponent } from "react";
import useMeetingStore from "../store/MeetingStore";
import ParticipantItem from "./ParticipantItem";
import UserParticipant from "./UserParticipant";

const ParticipantList: FunctionComponent = () => {
  const participants = useMeetingStore((state) => state.participants);

  return (
    <div className="w-full flex-1">
      <div className="text-white font-bold bg-gray-700 border-b border-gray-800 p-4 pl-8">
        <span>Participants</span>
        <span className="text-xs text-gray-400 ml-2">
          {/* All participants plus myself */}({participants.length + 1})
        </span>
      </div>
      <div className="w-full h-full overflow-y-scroll">
        <UserParticipant />
        {participants?.map((participant) => (
          <ParticipantItem key={participant.id} participant={participant} />
        ))}
      </div>
    </div>
  );
};

export default ParticipantList;
