import { FunctionComponent } from "react";
import useMeetingStore from "../store/MeetingStore";
import VideoGrid from "./VideoGrid";
import ScreenShareGrid from "./ScreenShareGrid";

const ConferenceGrid: FunctionComponent = () => {
  const screenSharingUser = useMeetingStore((state) => state.screenSharingUser);

  return <>{screenSharingUser ? <ScreenShareGrid /> : <VideoGrid />}</>;
};

export default ConferenceGrid;
