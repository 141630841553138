import { FunctionComponent, useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Select, Form } from "../../../Form";
import { MediaConstraints } from "../../utils/constraints";
import Modal from "../../../Modal/Modal";
import useUserMedia from "../../hooks/useUserMedia";
import useUserMediaStore from "../../store/UserMediaStore";
import useStreamStore from "../../store/StreamStore";

const SettingsButton: FunctionComponent = () => {
  const [openSettings, setOpenSettings] = useState(false);
  const { getUserMedia, getEnumeratedDevices } = useUserMedia();
  const hasVideoTrack = useStreamStore((state) => state.hasVideoTrack);
  const hasAudioTrack = useStreamStore((state) => state.hasAudioTrack);
  const devices = useUserMediaStore((state) => state.devices);
  const selectedCamera = useUserMediaStore((state) => state.selectedCamera);
  const selectedMicrophone = useUserMediaStore(
    (state) => state.selectedMicrophone
  );
  const selectedSpeaker = useUserMediaStore((state) => state.selectedSpeaker);
  const setSelectedCamera = useUserMediaStore(
    (state) => state.setSelectedCamera
  );
  const setSelectedMicrophone = useUserMediaStore(
    (state) => state.setSelectedMicrophone
  );
  const setSelectedSpeaker = useUserMediaStore(
    (state) => state.setSelectedSpeaker
  );

  const changeDevice = ({
    cameraId,
    microphoneId,
  }: {
    cameraId?: string;
    microphoneId?: string;
  }) => {
    const { video, audio } = JSON.parse(JSON.stringify(MediaConstraints));
    video.deviceId = { exact: selectedCamera };
    audio.deviceId = { exact: selectedMicrophone };

    if (cameraId) {
      video.deviceId = { exact: cameraId };
    }

    if (microphoneId) {
      audio.deviceId = { exact: microphoneId };
    }

    if (hasVideoTrack && hasAudioTrack) {
      getUserMedia({ video, audio });
    } else if (hasVideoTrack) {
      getUserMedia({ video });
    } else if (hasAudioTrack) {
      getUserMedia({ audio });
    }
  };

  useEffect(() => {
    navigator?.mediaDevices?.addEventListener(
      "devicechange",
      getEnumeratedDevices
    );

    return () => {
      navigator?.mediaDevices?.removeEventListener(
        "devicechange",
        getEnumeratedDevices
      );
    };
  }, []);

  return (
    <div>
      <div
        className="flex items-center"
        aria-label="Settings"
        data-balloon-pos="up"
      >
        <button
          type="button"
          onClick={() => setOpenSettings(true)}
          className="bg-white/20 rounded-full flex justify-center items-center rounded-full w-10 h-10"
        >
          <Icon icon="codicon:settings" className="las text-2xl text-white" />
        </button>
      </div>
      <Modal
        title="Device Settings"
        type="info"
        state={[openSettings, setOpenSettings]}
      >
        <Modal.Body>
          <Form className="flex flex-col gap-y-4 mb-4">
            <div>
              <Select
                name="camera"
                isClearable={false}
                state={[selectedCamera, setSelectedCamera]}
                options={
                  devices?.cameras?.map((camera) => ({
                    label: camera.label,
                    value: camera.deviceId,
                  })) ?? []
                }
                onChange={(cameraId) => changeDevice({ cameraId })}
              >
                Camera
              </Select>
            </div>
            <div>
              <Select
                name="microphone"
                isClearable={false}
                state={[selectedMicrophone, setSelectedMicrophone]}
                options={
                  devices?.microphones?.map((microphone) => ({
                    label: microphone.label,
                    value: microphone.deviceId,
                  })) ?? []
                }
                onChange={(microphoneId) => changeDevice({ microphoneId })}
              >
                Microphone
              </Select>
            </div>
            <div>
              <Select
                name="speaker"
                isClearable={false}
                state={[selectedSpeaker, setSelectedSpeaker]}
                options={
                  devices?.speakers?.map((speaker) => ({
                    label: speaker.label,
                    value: speaker.deviceId,
                  })) ?? []
                }
              >
                Speaker
              </Select>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingsButton;
