import Success from "./Notifications/Success";
import Error from "./Notifications/Error";

interface NotifyProps {
  message: string;
  status: string;
}

const Notify = ({ message, status }: NotifyProps) => {
  switch (status) {
    case "success":
      return Success({ message });
    case "error":
      return Error({ message });
    default:
      return null;
  }
};

export default Notify;
