import { FunctionComponent } from "react";
import { classNames } from "utils";

const Logo: FunctionComponent<{
  white?: boolean;
  minimized?: boolean;
  className?: string;
}> = ({ white = false, minimized = false, className = "" }) => {
  return (
    <div className={`flex items-center ${className}`} data-testid="logo">
      {!minimized && (
        <>
          <div
            className={classNames(
              white ? "text-white" : "text-blue-900",
              "tracking-tighter font-bold text-2xl"
            )}
          >
            Law
            <span className={classNames(white ? "text-white" : "text-sky-700")}>
              bind
            </span>
          </div>
          <span className="bg-yellow-200 text-yellow-700 text-[11px] font-medium px-1 rounded ml-2">
            Beta
          </span>
        </>
      )}
      {minimized && (
        <div
          className={classNames(
            white ? "text-white" : "text-blue-900",
            "tracking-tighter font-bold text-2xl"
          )}
        >
          LB
        </div>
      )}
    </div>
  );
};

export default Logo;
