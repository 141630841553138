import { toast } from "react-toastify";

interface SuccessProps {
  message: string;
}

const Success = ({ message }: SuccessProps) => {
  return toast.success(message, {
    style: {
      backgroundColor: "#dcfce7",
      color: "#14532d",
      fontWeight: 300,
      fontSize: "0.9rem",
    },
  });
};

export default Success;
