import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { io, Socket } from "socket.io-client";
// @ts-ignore
const URL = import.meta.env.VITE_API_URL as string;

interface SocketState {
  socket?: Socket;
  connect: () => void;
  disconnect: () => void;
}

const useSocketStore = create<SocketState>()(
  devtools((set, get) => ({
    socket: undefined,
    connect: () => {
      const existingSocket = get().socket;
      if (existingSocket) return; // Prevent multiple socket connections

      const socket = io(URL, {
        auth: { token: "token" },
      });

      socket.on("connect", () => {
        console.log("Connected ----");
        set((state) => {
          if (state.socket !== socket) {
            return { socket };
          }
          return state;
        });
      });

      socket.on("disconnect", () => {
        console.log("Disconnected ----");
        set((state) => {
          if (state.socket === socket) {
            return { socket: undefined };
          }
          return state;
        });
      });
    },
    disconnect: () => {
      const { socket } = get();
      if (!socket) return; // Prevent disconnect if already disconnected

      socket.disconnect();
      set((state) => {
        if (state.socket === socket) {
          return { socket: undefined };
        }
        return state;
      });
    },
  }))
);

export default useSocketStore;
