import { useEffect } from "react";
import useMeetingStore from "../store/MeetingStore";
import useStreamStore from "../store/StreamStore";

const useConference = () => {
  const hasScreenTrack = useStreamStore((state) => state.hasScreenTrack);
  const participants = useMeetingStore((state) => state.participants);
  const setGridUsers = useMeetingStore((state) => state.setGridUsers);
  const setScreenSharingUser = useMeetingStore(
    (state) => state.setScreenSharingUser
  );

  // A list of users to display in the video grid
  // Maximum of 4 users
  useEffect(() => {
    setGridUsers(participants?.slice(0, 4) ?? []);
  }, [participants]);

  // Determine if a user is sharing their screen
  useEffect(() => {
    if (hasScreenTrack) {
      const { me } = useMeetingStore.getState();
      const { stream, videoEnabled, audioEnabled } = useStreamStore.getState();
      if (stream && me) {
        return setScreenSharingUser({
          ...me,
          stream,
          videoEnabled,
          audioEnabled,
        });
      }
    }

    if (participants.length) {
      const screenSharingUser = participants.find((p) => p.hasScreenShare);
      if (screenSharingUser) {
        return setScreenSharingUser(screenSharingUser);
      }
    }

    setScreenSharingUser(undefined);
  }, [hasScreenTrack, participants]);

  return null;
};

export default useConference;
