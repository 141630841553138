import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const loadPath = "/locales/{{ns}}/{{lng}}.json";

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["navigator"],
      caches: [],
    },

    ns: ["default", "validation", "payout", "countries"],
    defaultNS: "default",

    supportedLngs: ["en", "pt-BR"],

    backend: { loadPath },

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18next;
