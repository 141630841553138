import email from "./email";
import equalTo from "./equalTo";
import max from "./max";
import maxLength from "./maxLength";
import min from "./min";
import minLength from "./minLength";
import phone from "./phone";
import required from "./required";

export default {
  email,
  equalTo,
  max,
  maxLength,
  min,
  minLength,
  phone,
  required,
};
