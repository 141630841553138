import { isDev } from "utils/constants";
import StreamStore from "../store/StreamStore";
const LIVE_STATE: MediaStreamTrackState = "live";

interface UserStreamProps {
  stream: MediaStream;
  isScreenShare?: boolean;
}

class UserStream extends MediaStream {
  private _isScreenShare: boolean;
  private _onChange: (params: Record<string, boolean>) => void;

  constructor({ stream, isScreenShare = false }: UserStreamProps) {
    super(stream);
    this._isScreenShare = isScreenShare;
    this._onChange = StreamStore.getState().setStreamParams;
    this.initialize();
  }

  private async initialize(): Promise<void> {
    // Wait for at least one track to be available and in the 'live' state
    await this.waitForTracks();
    this.setupTrackListeners();
    this.setupStreamListeners();
    this.updateStreamParams();
  }

  private updateStreamParams() {
    const params = {
      hasVideoTrack: this.hasVideoTrack,
      hasScreenTrack: this.hasScreenTrack,
      hasAudioTrack: this.hasAudioTrack,
      videoEnabled: this.videoEnabled,
      audioEnabled: this.audioEnabled,
    };
    this._onChange(params);
    if (isDev) {
      console.log(params);
    }
  }

  private waitForTracks(): Promise<void> {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();
      const timeout = 60000; // 1 minute in milliseconds

      const checkTracks = () => {
        if (this.getTracks().some((track) => track.readyState === LIVE_STATE)) {
          resolve();
        } else if (Date.now() - startTime >= timeout) {
          reject(new Error("Timeout: No live tracks found after 1 minute"));
        } else {
          setTimeout(checkTracks, 50); // Check every 50ms
        }
      };

      // If there are no tracks, resolve immediately
      this.getTracks().length ? checkTracks() : resolve();
    });
  }

  private setupTrackListeners() {
    this.getTracks().forEach(this.trackListeners);
  }

  // Overriding the addTrack
  // and to dispatch addtrack event since this is only dispatched by the browser
  // and NOT when manually adding tracks to the stream
  public addTrack(track: MediaStreamTrack): void {
    super.addTrack(track);
    this.trackListeners(track);
    this.dispatchEvent(new MediaStreamTrackEvent("addtrack", { track }));
  }
  // Overriding the removeTrack
  // and to dispatch removetrack event since this is only dispatched by the browser
  // and NOT when manually removing tracks to the stream
  public removeTrack(track: MediaStreamTrack): void {
    super.removeTrack(track);
    // Remove the listeners we added
    track.onended = null;
    track.onmute = null;
    track.onunmute = null;
    this.dispatchEvent(new MediaStreamTrackEvent("removetrack", { track }));
  }

  private setupStreamListeners() {
    this.addEventListener("addtrack", ({ track }) => {
      if (isDev) console.log("Track added...", track);
      this.updateStreamParams();
    });
    this.addEventListener("removetrack", ({ track }) => {
      if (isDev) console.log("Track removed...", track);
      this.updateStreamParams();
    });
    this.addEventListener("active", () => {
      if (isDev) console.log("Stream is active..");
      this.updateStreamParams();
    });
    this.addEventListener("inactive", () => {
      if (isDev) console.log("Stream is inactive...");
      this.updateStreamParams();
    });
  }

  private trackListeners = (track: MediaStreamTrack) => {
    track.onended = ({ target }) => {
      if (isDev) console.log("Track has ended...", target);
      this.updateStreamParams();
    };
    track.onmute = ({ target }) => {
      if (isDev) console.log("Track is muted...", target);
      this.updateStreamParams();
    };
    track.onunmute = ({ target }) => {
      if (isDev) console.log("Track is no longer muted...", target);
      this.updateStreamParams();
    };
  };

  public toggleVideo() {
    this.getVideoTracks().forEach((track) => (track.enabled = !track.enabled));
    this.updateStreamParams();
  }

  public toggleAudio() {
    this.getAudioTracks().forEach((track) => (track.enabled = !track.enabled));
    this.updateStreamParams();
  }

  public stopScreenShare() {
    if (this._isScreenShare) {
      this.getVideoTracks().forEach((track) => track.stop());
      this._isScreenShare = false;
      this.updateStreamParams();
    }
  }

  public stop() {
    this.getTracks().forEach((track) => track.stop());
    this.updateStreamParams();
  }

  public get videoEnabled(): boolean {
    return this.getVideoTracks().some(
      (track) => track.enabled && track.readyState === LIVE_STATE
    );
  }

  public get audioEnabled(): boolean {
    return this.getAudioTracks().some(
      (track) => track.enabled && track.readyState === LIVE_STATE
    );
  }

  public get hasVideoTrack(): boolean {
    return (
      !this._isScreenShare &&
      this.getVideoTracks().some((track) => track.readyState === LIVE_STATE)
    );
  }

  public get hasScreenTrack(): boolean {
    return (
      this._isScreenShare &&
      this.getVideoTracks().some((track) => track.readyState === LIVE_STATE)
    );
  }

  public get hasAudioTrack(): boolean {
    return this.getAudioTracks().some(
      (track) => track.readyState === LIVE_STATE
    );
  }
}

export default UserStream;
