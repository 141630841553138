import { create } from "zustand";
import { devtools } from "zustand/middleware";
import UserStream from "../utils/UserStream";

interface StreamStoreProps {
  stream?: UserStream;
  videoEnabled: boolean;
  audioEnabled: boolean;
  hasVideoTrack: boolean;
  hasScreenTrack: boolean;
  hasAudioTrack: boolean;
  isTalking: boolean;
  setStream: (stream: UserStream) => void;
  setStreamParams: (params: {
    videoEnabled?: boolean;
    audioEnabled?: boolean;
    hasVideoTrack?: boolean;
    hasScreenTrack?: boolean;
    hasAudioTrack?: boolean;
  }) => void;
  setIsTalking: (isTalking: boolean) => void;
}

const StreamStore = create(
  devtools<StreamStoreProps>((set, get) => ({
    stream: undefined,
    videoEnabled: false,
    audioEnabled: false,
    hasVideoTrack: false,
    hasScreenTrack: false,
    hasAudioTrack: false,
    isTalking: false,
    setStream: (stream) => {
      set({ stream });
    },
    setStreamParams: (params) => {
      const {
        videoEnabled,
        audioEnabled,
        hasVideoTrack,
        hasScreenTrack,
        hasAudioTrack,
      } = get();
      set({
        videoEnabled,
        audioEnabled,
        hasVideoTrack,
        hasScreenTrack,
        hasAudioTrack,
        ...params,
      });
    },
    setIsTalking: (isTalking) => {
      set({ isTalking });
    },
  }))
);

export default StreamStore;
