import { FunctionComponent, useRef, useState, useEffect } from "react";
import MediaControls from ".";
import Avatar from "../../Avatar";
import useMeetingStore from "../store/MeetingStore";
import useUserMediaStore from "../store/UserMediaStore";
import useStreamStore from "../store/StreamStore";

// const openSettings = () => {
//   let settingsUrl;
//   const userAgent = navigator.userAgent.toLowerCase();

//   if (userAgent.includes("chrome")) {
//     settingsUrl = "chrome://settings/content/camera";
//   } else if (userAgent.includes("edge")) {
//     settingsUrl = "edge://settings/content/camera";
//   } else if (userAgent.includes("firefox")) {
//     settingsUrl = "about:preferences#privacy";
//   } else {
//     alert(
//       "Please go to your browser settings to enable camera access for this site."
//     );
//     return;
//   }

//   window.open(settingsUrl, "_blank");
// };

const MediaSetup: FunctionComponent = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mediaDenied, setMediaDenied] = useState(false);
  const [buttonMediaText, setButtonMediaText] = useState<string | null>(null);
  const [mediaWarningText, setMediaWarningText] = useState<string | null>(null);
  const me = useMeetingStore((state) => state.me);
  const stream = useStreamStore((state) => state.stream);
  const videoEnabled = useStreamStore((state) => state.videoEnabled);
  const permissions = useUserMediaStore((state) => state.permissions);

  const requestMedia = async () => {
    if (mediaDenied) {
      return alert("You need to allow media permissions to join the meeting.");
    }
  };

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef.current]);

  useEffect(() => {
    const { camera, microphone } = permissions;

    if (camera === "denied" && microphone === "denied") {
      setMediaWarningText(
        "Do you want people to see and hear you in the meeting?"
      );
      setButtonMediaText("Allow camera and microphone");
    } else if (camera === "denied") {
      setMediaWarningText("Do you want people to see you in the meeting?");
      setButtonMediaText("Allow camera");
    } else if (microphone === "denied") {
      setMediaWarningText("Do you want people to hear you in the meeting?");
      setButtonMediaText("Allow microphone");
    } else {
      setMediaWarningText(null);
      setButtonMediaText(null);
    }

    setMediaDenied(
      camera === "denied" || microphone === "denied" ? true : false
    );
  }, [permissions]);

  return (
    <div className="bg-black w-full max-w-[600px] h-96 flex flex-col relative rounded-md shadow-xl">
      <div className="flex flex-col justify-center items-center flex-1">
        {!videoEnabled && me && (
          <div className="justify-center items-center relative z-20">
            <Avatar
              firstName={me?.firstName}
              lastName={me?.lastName}
              image={me?.image}
              className="w-24 h-24 bg-white text-black text-3xl object-cover rounded-full"
            />
          </div>
        )}
        {!videoEnabled && (
          <p className="text-white text-md text-center mt-4 relative z-20">
            {mediaWarningText
              ? mediaWarningText
              : "Adjust your video and audio settings"}
          </p>
        )}
        {buttonMediaText && (
          <button
            type="button"
            onClick={() => requestMedia()}
            className="text-white bg-blue-600 text-sm font-medium px-4 py-1 rounded relative z-50 mt-4"
          >
            {buttonMediaText}
          </button>
        )}
      </div>
      <video
        ref={videoRef}
        className="w-full h-full -scale-x-100 object-cover rounded-md absolute z-10"
        autoPlay
        muted
        playsInline
      />
      <div className="w-full mt-auto mb-2 relative z-50 flex flex-col justify-center items-center">
        <div className="relative flex justify-center items-center w-full">
          <MediaControls />
        </div>
      </div>
    </div>
  );
};

export default MediaSetup;
