import equals from "validator/lib/equals";

interface EqualTo {
  equalTo: {
    name?: string;
    id?: string;
    label: string;
  };
}

export default (value: string, { equalTo }: EqualTo, t: Function) => {
  const { name, id, label } = equalTo;

  if (!label) {
    throw new Error("Missing label in equalTo validation rule");
  }

  if (!name && !id) {
    throw new Error("Missing name or id in equalTo validation rule");
  }

  const selector = name ? `[name=${name}]` : `#${id}`;

  const el: HTMLInputElement | null = document.querySelector(selector);

  if (!el) {
    throw new Error(`Missing element with selector ${selector}`);
  }

  return (
    equals(el.value, value) ||
    t("This value must be equal to", { label: label?.toLowerCase() })
  );
};
