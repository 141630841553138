import { useState } from "react";
import { MediaConstraints } from "../utils/constraints";
import useUserMediaStore from "../store/UserMediaStore";
import useStreamStore from "../store/StreamStore";
import { areArraysOfObjectsEqual } from "utils/compare";

const useUserMedia = () => {
  const [userMediaTries, setUserMediaTries] = useState(0);
  const stream = useStreamStore((state) => state.stream);
  const permissions = useUserMediaStore((state) => state.permissions);
  const devices = useUserMediaStore((state) => state.devices);
  const selectedCamera = useUserMediaStore((state) => state.selectedCamera);
  const selectedMicrophone = useUserMediaStore(
    (state) => state.selectedMicrophone
  );
  const selectedSpeaker = useUserMediaStore((state) => state.selectedSpeaker);
  const setPermissions = useUserMediaStore((state) => state.setPermissions);
  const setDevices = useUserMediaStore((state) => state.setDevices);
  const setSelectedCamera = useUserMediaStore(
    (state) => state.setSelectedCamera
  );
  const setSelectedMicrophone = useUserMediaStore(
    (state) => state.setSelectedMicrophone
  );
  const setSelectedSpeaker = useUserMediaStore(
    (state) => state.setSelectedSpeaker
  );

  const getUserMedia = async (
    constraints: MediaStreamConstraints = MediaConstraints
  ): Promise<MediaStream | undefined> => {
    const { video, audio } = constraints;
    const params: MediaStreamConstraints = {};

    if (video) params.video = video;
    if (audio) params.audio = audio;

    return navigator.mediaDevices
      .getUserMedia(params)
      .then((mediaStream: MediaStream) => {
        const videoReady = mediaStream?.getVideoTracks().length > 0;
        const audioReady = mediaStream?.getAudioTracks().length > 0;

        getEnumeratedDevices();

        // Enable / disable video if stream is already available
        if (stream?.hasVideoTrack) {
          const videoEnabled = !!stream?.videoEnabled;
          mediaStream
            ?.getVideoTracks()
            .forEach((t) => (t.enabled = videoEnabled));
        }
        // Enable / disable audio if stream is already available
        if (stream?.hasAudioTrack) {
          const audioEnabled = !!stream?.audioEnabled;
          mediaStream
            ?.getAudioTracks()
            .forEach((t) => (t.enabled = audioEnabled));
        }

        // Select the camera if it's available
        mediaStream?.getVideoTracks().forEach((track) => {
          const camera = devices.cameras.find(
            (c) => c.deviceId === track.getSettings().deviceId
          );
          if (camera) setSelectedCamera(camera.deviceId);
        });

        // Select the microphone if it's available
        mediaStream?.getAudioTracks().forEach((track) => {
          const microphone = devices.microphones.find(
            (m) => m.deviceId === track.getSettings().deviceId
          );
          if (microphone) setSelectedMicrophone(microphone.deviceId);
        });

        // Update the permissions state if the user granted the permission
        const newPermissions = {
          camera: videoReady && params.video ? "granted" : permissions.camera,
          microphone:
            audioReady && params.audio ? "granted" : permissions.microphone,
        };

        setPermissions(newPermissions);
        setUserMediaTries(0);

        return mediaStream;
      })
      .catch((e: any) => {
        console.log(e);

        const { camera, microphone } = permissions;

        switch (e.name) {
          case "NotAllowedError":
          case "NotFoundError":
            setPermissions({
              camera: video ? "denied" : camera,
              microphone: audio ? "denied" : microphone,
            });
            break;
          case "OverconstrainedError":
            const tries = userMediaTries + 1;
            setUserMediaTries(tries);
            // Try again with the default constraints
            if (tries < 2) {
              return getUserMedia({ audio: true, video: true });
            }
            break;
          default:
            break;
        }

        return undefined;
      });
  };

  const getDisplayMedia = async (): Promise<MediaStream | undefined> => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      return screenStream;
    } catch (e: any) {
      console.log(e);

      switch (e.name) {
        case "NotAllowedError":
        case "NotFoundError":
          break;
        default:
          break;
      }

      return undefined;
    }
  };

  const getEnumeratedDevices = async () => {
    if (navigator?.mediaDevices?.enumerateDevices) {
      try {
        const data = await navigator.mediaDevices.enumerateDevices();
        const cameras = data.filter((d) => d.kind === "videoinput");
        const microphones = data.filter((d) => d.kind === "audioinput");
        const speakers = data.filter((d) => d.kind === "audiooutput");
        // Update the devices state only if they have changed
        // This is to avoid unnecessary re-renders
        if (
          !areArraysOfObjectsEqual(cameras, devices.cameras) ||
          !areArraysOfObjectsEqual(microphones, devices.microphones) ||
          !areArraysOfObjectsEqual(speakers, devices.speakers)
        ) {
          setDevices({ cameras, microphones, speakers });
        }

        // Select the first available device as the default selected device
        if (!selectedCamera && cameras.length) {
          setSelectedCamera(cameras[0].deviceId);
        }
        if (!selectedMicrophone && microphones.length) {
          setSelectedMicrophone(microphones[0].deviceId);
        }
        if (!selectedSpeaker && speakers.length) {
          setSelectedSpeaker(speakers[0].deviceId);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const checkPermissions = async () => {
    if (navigator.permissions) {
      try {
        const cameraPermission = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });
        const microphonePermission = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });
        // Update the permissions state only if they have changed
        // This is to avoid unnecessary re-renders
        if (
          permissions.camera !== cameraPermission.state ||
          permissions.microphone !== microphonePermission.state
        ) {
          console.log("Permissions changed");
          setPermissions({
            camera: cameraPermission.state,
            microphone: microphonePermission.state,
          });
        }
      } catch {}
    }
  };

  return {
    checkPermissions,
    getEnumeratedDevices,
    getUserMedia,
    getDisplayMedia,
  };
};

export default useUserMedia;
