import { create } from "zustand";
import { devtools } from "zustand/middleware";
import UserStream from "../utils/UserStream";
export interface Participant {
  id: string;
  stream?: MediaStream | UserStream;
  firstName?: string;
  lastName?: string;
  image?: any;
  isTalking?: boolean;
  videoEnabled?: boolean;
  audioEnabled?: boolean;
  hasScreenShare?: boolean;
}

export interface Me {
  id: string;
  firstName: string;
  lastName: string;
  image?: any;
}

interface MeetingStoreProps {
  roomId?: string;
  me?: Me;
  participants: Participant[];
  screenSharingUser?: Participant;
  gridUsers: Participant[];
  joinRoom: (roomId: string) => void;
  leaveRoom: () => void;
  setMe: (me: any) => void;
  findParticipant: (participantId: string) => Participant | undefined;
  setParticipant: (participant: Participant) => void;
  removeParticipant: (participantId: string) => void;
  setParticipants: (participants: Participant[]) => void;
  setScreenSharingUser: (screenSharingUser?: Participant) => void;
  setGridUsers: (gridUsers: Participant[]) => void;
}

const useMeetingStore = create(
  devtools<MeetingStoreProps>((set, get) => ({
    me: undefined,
    roomId: undefined,
    participants: [],
    screenSharingUser: undefined,
    gridUsers: [],
    joinRoom: (roomId) => {
      set({ roomId });
    },
    leaveRoom: () => {
      set({ roomId: undefined });
    },
    setMe: (me) => {
      if (!me.image) {
        delete me.image;
      }

      set({ me });
    },
    findParticipant: (participantId) => {
      const { participants } = get();
      return participants.find((p) => p.id === participantId);
    },
    setParticipant: (participant) => {
      const { participants } = get();

      const index = participants.findIndex((p) => p.id === participant.id);

      if (index === -1) {
        return set({ participants: [...participants, participant] });
      }

      const newParticipants = [...participants];
      newParticipants[index] = { ...newParticipants[index], ...participant };

      set({ participants: newParticipants });
    },
    removeParticipant: (participantId) => {
      const { participants } = get();

      set({ participants: participants.filter((p) => p.id !== participantId) });
    },
    setParticipants: (participants) => {
      set({ participants });
    },
    setScreenSharingUser: (screenSharingUser) => {
      set({ screenSharingUser });
    },
    setGridUsers: (gridUsers) => {
      set({ gridUsers });
    },
  }))
);

export default useMeetingStore;
