import { useEffect } from "react";
import { debounce } from "lodash";
import { MediaConstraints } from "../utils/constraints";
import useMeetingStore from "../store/MeetingStore";
import useUserMediaStore from "../store/UserMediaStore";
import useStreamStore from "../store/StreamStore";
import useSocketStore from "../store/SocketStore";
import useUserMedia from "./useUserMedia";
import UserStream from "../utils/UserStream";

type PermissionState = "granted" | "denied" | "prompt";

interface Permissions {
  camera: PermissionState;
  microphone: PermissionState;
}

const useMediaToggle = () => {
  const socket = useSocketStore((state) => state.socket);
  const stream = useStreamStore((state) => state.stream);
  const setStream = useStreamStore((state) => state.setStream);
  const videoEnabled = useStreamStore((state) => state.videoEnabled);
  const audioEnabled = useStreamStore((state) => state.audioEnabled);
  const hasScreenTrack = useStreamStore((state) => state.hasScreenTrack);
  const roomId = useMeetingStore((state) => state.roomId);
  const permissions: Permissions = useUserMediaStore(
    (state) => state.permissions
  );
  const selectedCamera = useUserMediaStore((state) => state.selectedCamera);
  const selectedMicrophone = useUserMediaStore(
    (state) => state.selectedMicrophone
  );
  const { getUserMedia, getDisplayMedia } = useUserMedia();

  const toggleVideo = async (): Promise<boolean> => {
    if (permissions.camera === "denied") return false;

    try {
      if (stream?.hasScreenTrack) {
        stream.stopScreenShare();
      }

      if (!stream?.hasVideoTrack) {
        const video: MediaTrackConstraints = { ...MediaConstraints.video };
        if (selectedCamera) video.deviceId = { exact: selectedCamera };

        const videoStream = await getUserMedia({ video });
        if (!videoStream) return false;

        if (stream?.hasAudioTrack) {
          videoStream.addTrack(stream.getAudioTracks()[0]);
        }

        const userStream = new UserStream({ stream: videoStream });

        setStream(userStream);

        return userStream.videoEnabled;
      }

      stream.toggleVideo();
      return stream.videoEnabled;
    } catch (error) {
      console.error("Error toggling video:", error);
      return false;
    }
  };

  const toggleAudio = async (): Promise<boolean> => {
    if (permissions.microphone === "denied") return false;

    try {
      if (!stream?.hasAudioTrack) {
        const audio: MediaTrackConstraints = { ...MediaConstraints.audio };
        if (selectedMicrophone) audio.deviceId = { exact: selectedMicrophone };

        const audioStream = await getUserMedia({ audio });
        if (!audioStream) return false;

        if (stream?.hasVideoTrack) {
          audioStream.addTrack(stream.getVideoTracks()[0]);
        }

        const userStream = new UserStream({ stream: audioStream });

        setStream(userStream);

        return userStream.audioEnabled;
      }

      stream.toggleAudio();
      return stream.audioEnabled;
    } catch (error) {
      console.error("Error toggling audio:", error);
      return false;
    }
  };

  const toggleScreenShare = async (): Promise<boolean> => {
    if (permissions.camera === "denied") return false;

    try {
      if (!stream?.hasScreenTrack) {
        const screenStream = await getDisplayMedia();
        if (!screenStream) return false;

        if (stream?.hasAudioTrack) {
          screenStream.addTrack(stream.getAudioTracks()[0]);
        } else {
          const audio: MediaTrackConstraints = { ...MediaConstraints.audio };
          if (selectedMicrophone) {
            audio.deviceId = { exact: selectedMicrophone };
          }

          const audioStream = await getUserMedia({ audio });
          if (!audioStream) return false;

          screenStream.addTrack(audioStream.getAudioTracks()[0]);
        }

        setStream(
          new UserStream({ stream: screenStream, isScreenShare: true })
        );
        return true;
      }

      stream.stopScreenShare();
      return false;
    } catch (error) {
      console.error("Error toggling screen share:", error);
      return false;
    }
  };

  const debouncedEmit = debounce(
    (
      roomId: string,
      videoEnabled: boolean,
      audioEnabled: boolean,
      hasScreenShare: boolean
    ) => {
      socket?.emit("meeting.user.status", {
        roomId,
        videoEnabled,
        audioEnabled,
        hasScreenShare,
      });
    },
    300
  );

  useEffect(() => {
    if (!roomId) return;

    debouncedEmit(roomId, videoEnabled, audioEnabled, hasScreenTrack);

    return () => {
      debouncedEmit.cancel();
    };
  }, [videoEnabled, audioEnabled, hasScreenTrack, roomId]);

  return {
    toggleVideo,
    toggleAudio,
    toggleScreenShare,
  };
};

export default useMediaToggle;
