import { useEffect, useRef } from "react";
import { IceConfiguration } from "../utils/constraints";
import { log } from "utils";
import useMeetingStore from "../store/MeetingStore";
import useStreamStore from "../store/StreamStore";
import useSocketStore from "../store/SocketStore";
import adapter from "webrtc-adapter";

const useWebRTC = () => {
  const socket = useSocketStore((state) => state.socket);
  const stream = useStreamStore((state) => state.stream);
  const roomId = useMeetingStore((state) => state.roomId);
  const setParticipant = useMeetingStore((state) => state.setParticipant);
  const connectionsRef = useRef<Record<string, RTCPeerConnection>>({});
  const getStreamStoreState = useStreamStore.getState;

  const createPeerConnection = (
    participantId: string
  ): Promise<RTCPeerConnection> => {
    return new Promise((resolve) => {
      const connection = new RTCPeerConnection(IceConfiguration);
      // Get current stream state directly from the store
      // This is to ensure that the stream state is always up-to-date
      const { stream: currentStream } = getStreamStoreState();

      log("Creating peer connection for", participantId);

      if (currentStream) {
        log("With current stream", currentStream);

        currentStream.getTracks().forEach((track) => {
          connection.addTrack(track, currentStream);
        });
      }

      connection.onicecandidate = (event) => {
        if (event.candidate) {
          socket?.emit("meeting.ice.candidate", {
            roomId,
            candidate: event.candidate,
          });
        }
      };

      connection.ontrack = (event) => {
        const [remoteStream] = event.streams;
        setParticipant({ id: participantId, stream: remoteStream });
      };

      connection.onnegotiationneeded = async () => {
        try {
          await createOffer(participantId, connection);
        } catch (error) {
          console.error("Error during renegotiation", error);
        }
      };

      connection.oniceconnectionstatechange = () => {
        log(
          `ICE connection state for ${participantId}:`,
          connection.iceConnectionState
        );

        switch (connection.iceConnectionState) {
          case "checking":
            log("Connecting...");
            break;
          case "connected":
            log("Connected...");
            break;
          case "disconnected":
            log(
              `Disconnected from ${participantId}, attempting to reconnect...`
            );
            handleICEDisconnection(participantId);
            break;
          case "failed":
            log(
              `Connection failed with ${participantId}, attempting to restart ICE`
            );
            handleICEConnectionFailure(participantId);
            break;
          case "closed":
            log(`Connection closed with ${participantId}`);
            break;
        }
      };

      connectionsRef.current[participantId] = connection;

      return resolve(connection);
    });
  };

  const handleOffer = async (
    participantId: string,
    offer: RTCSessionDescriptionInit
  ) => {
    let connection = connectionsRef.current[participantId];

    if (connection) {
      // If connection exists but we're receiving a new offer, let's reset it
      log("Resetting existing connection during offer handling", connection);
      await closeConnection(participantId);
    }

    connection = await createPeerConnection(participantId);

    try {
      await connection.setRemoteDescription(new RTCSessionDescription(offer));
      const answer = await connection.createAnswer();
      await connection.setLocalDescription(answer);
      socket?.emit("meeting.media.answer", {
        toId: participantId,
        answer,
      });
    } catch (error) {
      console.error("Error handling offer", error);
    }
  };

  const handleAnswer = async (
    participantId: string,
    answer: RTCSessionDescriptionInit
  ) => {
    const connection = connectionsRef.current[participantId];

    if (!connection) {
      log(
        `No connection found for participant ${participantId} when handling answer`
      );
      return;
    }

    log(
      `Handling answer for ${participantId}. Current signaling state: ${connection.signalingState}`
    );

    try {
      // Wait for the connection to be in a stable state or have a local offer
      await waitForStableState(connection);

      if (connection.signalingState === "have-local-offer") {
        await connection.setRemoteDescription(
          new RTCSessionDescription(answer)
        );
        log(`Successfully set remote description for ${participantId}`);
      } else {
        log(
          `Unexpected signaling state '${connection.signalingState}' for ${participantId} when handling answer`
        );
      }
    } catch (error) {
      console.error(`Error handling answer for ${participantId}:`, error);
    }
  };

  const handleCandidate = async (
    participantId: string,
    candidate: RTCIceCandidateInit
  ) => {
    const connection = connectionsRef.current[participantId];
    if (
      connection &&
      connection.remoteDescription &&
      connection.iceConnectionState !== "closed"
    ) {
      try {
        await connection.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (error) {
        console.error("Error handling candidate", error);
      }
    }
  };

  const createOffer = async (
    participantId: string,
    connection: RTCPeerConnection
  ) => {
    try {
      log(
        `Creating offer for ${participantId}. Current signaling state: ${connection.signalingState}`
      );

      // Wait for stable state before creating offer
      await waitForStableState(connection);

      const offerOptions: RTCOfferOptions = {
        offerToReceiveAudio: true,
        offerToReceiveVideo: true,
        iceRestart: true,
      };

      const offer = await connection.createOffer(offerOptions);
      log(`Offer created for ${participantId}`);

      await connection.setLocalDescription(offer);
      log(`Local description set for ${participantId}`);

      // Wait for ICE gathering to complete
      await waitForIceGathering(connection);

      // Get the latest offer after ICE gathering is complete
      const finalOffer = connection.localDescription;

      socket?.emit("meeting.media.offer", {
        toId: participantId,
        offer: finalOffer,
      });
      log(`Final offer sent to ${participantId}`);
    } catch (error) {
      console.error(`Error creating offer for ${participantId}:`, error);
    }
  };

  const callParticipant = async (participantId: string) => {
    log(`Calling participant ${participantId}`);

    if (connectionsRef.current[participantId]) {
      log(
        "Connection already exists, closing it first before calling it again..."
      );
      // If connection exists then close it before calling again
      await closeConnection(participantId);
    }

    const connection = await createPeerConnection(participantId);

    createOffer(participantId, connection);
  };

  const closeConnection = (participantId: string): Promise<boolean> => {
    return new Promise(async (resolve) => {
      const connection = connectionsRef.current[participantId];
      // By removing the connection from connectionsRef before closing it, we ensure that:
      // The connection is no longer accessible via connectionsRef when the 'disconnected' or 'closed' event listeners are triggered.
      // handleICEDisconnection won't attempt to restart ICE on a connection that's being intentionally closed.
      delete connectionsRef.current[participantId];

      if (connection) {
        log("Closing connection", connection);
        connection.getSenders().forEach((sender) => {
          connection.removeTrack(sender);
        });
        connection.close();
      }

      return resolve(true);
    });
  };

  const closeAllConnections = (): Promise<boolean> => {
    return new Promise(async (resolve) => {
      log("Cleaning up WebRTC connections");

      const ids = Object.keys(connectionsRef.current);

      for await (const participantId of ids) {
        await closeConnection(participantId);
      }

      return resolve(true);
    });
  };

  const handleICEDisconnection = (participantId: string) => {
    const connection = connectionsRef.current[participantId];
    if (connection && connection.iceConnectionState !== "closed") {
      connection.restartIce();
    }
  };

  const handleICEConnectionFailure = async (participantId: string) => {
    // Close the existing connection
    await closeConnection(participantId);
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
    // Call the participant again
    callParticipant(participantId);
  };

  const waitForStableState = (
    connection: RTCPeerConnection,
    timeout: number = 10000
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (
        connection.signalingState === "stable" ||
        connection.signalingState === "have-local-offer"
      ) {
        resolve();
        return;
      }

      const timer = setTimeout(() => {
        reject(new Error("Timeout waiting for stable state"));
      }, timeout);

      connection.addEventListener(
        "signalingstatechange",
        function onStateChange() {
          if (
            connection.signalingState === "stable" ||
            connection.signalingState === "have-local-offer"
          ) {
            clearTimeout(timer);
            connection.removeEventListener(
              "signalingstatechange",
              onStateChange
            );
            resolve();
          }
        }
      );
    });
  };

  const waitForIceGathering = (
    connection: RTCPeerConnection,
    timeout: number = 10000
  ): Promise<void> => {
    return new Promise((resolve) => {
      if (connection.iceGatheringState === "complete") {
        resolve();
        return;
      }

      const checkState = () => {
        if (connection.iceGatheringState === "complete") {
          connection.removeEventListener("icegatheringstatechange", checkState);
          resolve();
        }
      };

      connection.addEventListener("icegatheringstatechange", checkState);

      // Set a timeout to resolve anyway after 10 seconds
      setTimeout(resolve, timeout);
    });
  };

  useEffect(() => {
    if (stream) {
      log("Stream has changed, let's update the connections", stream);
      Object.values(connectionsRef.current).forEach((connection) => {
        // By removing all existing tracks instead of replacing existing ones with replaceTrack method,
        // you ensure there's no conflict or leftover state from previous tracks as some cases
        // replacing tracks might not be handled properly by the browser
        if (connection) {
          log("Updating connection with new stream", connection);
          // Remove all existing senders
          connection.getSenders().forEach((sender) => {
            connection.removeTrack(sender);
          });

          // Add all tracks from the new stream
          stream.getTracks().forEach((track) => {
            connection.addTrack(track, stream);
          });
        }
      });
    }
  }, [stream]);

  useEffect(() => {
    if (!socket?.connected) return;

    const handleIceCandidate = async ({ id, candidate }: any) => {
      await handleCandidate(id, candidate);
    };

    const handleMediaOffer = async ({ fromId, offer }: any) => {
      await handleOffer(fromId, offer);
    };

    const handleMediaAnswer = async ({ fromId, answer }: any) => {
      await handleAnswer(fromId, answer);
    };

    socket?.on("meeting.ice.candidate", handleIceCandidate);
    socket?.on("meeting.media.offer", handleMediaOffer);
    socket?.on("meeting.media.answer", handleMediaAnswer);

    return () => {
      socket?.off("meeting.ice.candidate", handleIceCandidate);
      socket?.off("meeting.media.offer", handleMediaOffer);
      socket?.off("meeting.media.answer", handleMediaAnswer);
    };
  }, [socket]);

  useEffect(() => {
    log("WebRTC adapter version:", adapter.browserDetails.version);
    log("Browser:", adapter.browserDetails.browser);
  }, []);

  return {
    createPeerConnection,
    handleCandidate,
    handleOffer,
    handleAnswer,
    callParticipant,
    closeConnection,
    closeAllConnections,
  };
};

export default useWebRTC;
