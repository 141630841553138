import { FunctionComponent } from "react";
import { Icon } from "@iconify/react";
import useMeetingStore from "../../store/MeetingStore";
import useStreamStore from "../../store/StreamStore";
import useSocketStore from "../../store/SocketStore";

interface LeaveCallButtonProps {
  closeAllConnections: () => Promise<boolean>;
}

const LeaveCallButton: FunctionComponent<LeaveCallButtonProps> = ({
  closeAllConnections,
}) => {
  const socket = useSocketStore((state) => state.socket);
  const roomId = useMeetingStore((state) => state.roomId);
  const stream = useStreamStore((state) => state.stream);
  const leaveRoom = useMeetingStore((state) => state.leaveRoom);
  const setParticipants = useMeetingStore((state) => state.setParticipants);

  const handleLeaveRoom = async () => {
    await closeAllConnections();
    setParticipants([]);
    stream?.stop();
    leaveRoom();
    socket?.emit("meeting.user.leave", roomId);
  };

  return (
    <div
      className="flex items-center"
      aria-label="Leave call"
      data-balloon-pos="up"
    >
      <button
        type="button"
        onClick={handleLeaveRoom}
        className="bg-red-600 rounded-full flex justify-center items-center rounded-full w-10 h-10"
      >
        <Icon
          data-testid="hangup-icon"
          icon="mdi:phone-hangup"
          className="las text-2xl text-white"
        />
      </button>
    </div>
  );
};

export default LeaveCallButton;
