import { FunctionComponent, useEffect } from "react";

const SEO: FunctionComponent<any> = ({ head }) => {
  useEffect(() => {
    if (head) {
      head.forEach((tag: any) => {
        if (tag.name) {
          document.head.querySelector(`meta[name="${tag.name}"]`)?.remove();

          const newMetaTag = document.createElement("meta");
          newMetaTag.setAttribute("name", tag.name);
          newMetaTag.setAttribute("content", tag.content);
          document.head.appendChild(newMetaTag);
        }

        if (tag.property) {
          document.head
            .querySelector(`meta[property="${tag.property}"]`)
            ?.remove();

          const newMetaTag = document.createElement("meta");
          newMetaTag.setAttribute("property", tag.property);
          newMetaTag.setAttribute("content", tag.content);
          document.head.appendChild(newMetaTag);
        }

        if (tag.rel) {
          document.head.querySelector(`link[rel="${tag.rel}"]`)?.remove();

          const newLinkTag = document.createElement("link");
          newLinkTag.setAttribute("rel", tag.rel);
          newLinkTag.setAttribute("href", tag.href);
          document.head.appendChild(newLinkTag);
        }

        if (tag.title) {
          document.title = tag.title;
        }
      });
    }

    return () => {
      if (head) {
        head.forEach((tag: any) => {
          if (tag.name) {
            document.head.querySelector(`meta[name="${tag.name}"]`)?.remove();
          }
          if (tag.property) {
            document.head
              .querySelector(`meta[property="${tag.property}"]`)
              ?.remove();
          }
          if (tag.rel) {
            document.head.querySelector(`link[rel="${tag.rel}"]`)?.remove();
          }
          if (tag.title) {
            document.title = "";
          }
        });
      }
    };
  }, [head]);

  // The component doesn't render any visible content
  return null;
};

export default SEO;
